import React from "react"
import headerImage from "../../images/about.png"

function AboutHeader(props) {
    return (
        <div className="about-header">
            <div className="column mb-0">
                <div className="title is-2">{props.title}</div>
            </div>
            {props.content.map(t => (
                <div className="is-size-4 column">{t}</div>
            ))}
            <div className="about-image mb-6">
                <img className="rounded py-3" src={headerImage} />
            </div>
        </div>
    )
}

export default AboutHeader