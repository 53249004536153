import React from "react"
import AboutLinksBlock from "./about-links-block"

function AboutLinksBlocks(props) {
    return (
        <div className="columns about-links-blocks">
            {props.aboutLinksBlocks.map(x =>
                <AboutLinksBlock icon={x.icon} title={x.title} links={x.links} />
            )}
        </div>
    )
}

export default AboutLinksBlocks