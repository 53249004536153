import React from "react"
import LinkWithIcon from "../link-with-icon";

function AboutLinksBlock(props) {
    return (
        <div className="column about-links-block px-5">
            <LinkWithIcon icon={props.icon} name={props.title} size="is-3" />
            <div className="project-links">
                {props.links.map(x => (
                    <a className="column is-full" href={x.link}>{x.text}</a>
                ))}
            </div>
        </div>
    )
}

export default AboutLinksBlock