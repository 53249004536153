import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { AboutHeader, AboutLinksBlocks } from "../components/about"
import { mdiBriefcaseOutline } from '@mdi/js';
import { mdiNotebookEditOutline } from '@mdi/js';


function AboutPage() {

    const headerTexts = [
        `PolkadotCenter is a community driven project that aims to give an open place for ecosystem 
    projects to share their latest news.`,
        `Contributions are from the ecosystem and for the community.`];

    const aboutLinksBlocks = [
        {
            icon: mdiBriefcaseOutline,
            title: "Project listing information",
            links: [
            ]
        },
        {
            icon: mdiNotebookEditOutline,
            title: "Publish Article instructions",
            links: [
            ]
        },
    ]

    return (
        <Layout>
            <SEO title="About" />
            <div className="mgt-medium column page-content about-page">
                <AboutHeader title="About Us" content={headerTexts} />
                <AboutLinksBlocks aboutLinksBlocks={aboutLinksBlocks} />
            </div>
            <hr></hr>
            <div>
                <p>Acria UG (haftungsbeschränkt)<br></br>
                Thurn-und-Taxis-Platz 6<br></br>
                60313 Frankfurt am Main<br></br>
                Germany<br></br></p>

                <b>Represented by:</b>
                <p>Dominik Ernst<br></br></p>


                <b>Contact:</b>
                <p>Email: help@acria.network<br></br></p>
                    
                <b>Register:</b>
                <p>Register number: HRB 121953<br></br>
                Register Court: Amtsgericht Frankfurt am Main
                </p>
            </div>
        </Layout>
    )
}

export default AboutPage
